<template>
  <div v-if="produit != false">
    <b-card class="card-custom mt-3 card-titre sticky-top" no-body style="z-index: 25;">
      <template #header>
        <div class="card-title">
          <h3 class="card-label">Produit <strong>#{{produit.id}} - {{produit.mutuelle}} {{ produit.produit }} {{ produit.formule }}</strong></h3>
        </div>        
        <div class="card-title">                              
          <b-button v-if="produit.id != null && $api.user.id === 1" v-b-tooltip.hover title="Maj docs prdoduits" @click="save('documents')" variant="light-info" class="btn-icon" size="sm">
            <i class="fa fa-file"></i>
          </b-button>
          <b-button v-if="produit.id != null" v-b-tooltip.hover title="Clonez le produit" @click="save('clone')" variant="light-danger" class="btn-icon ml-1" size="sm">
            <i class="fa fa-clone"></i>
          </b-button>
          <b-button v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon ml-1" @click="save()" size="sm">
            <i v-if="save_loader === false" class="fas fa-save"></i>
            <i v-else class="fas fa-spinner fa-spin"></i>
          </b-button>
        </div>
      </template>
    </b-card>
    <form ref="form" class="mt-3">
      <b-card-group deck>
        <b-card class="card-search">
          <b-row>
            <FieldForm model="MutuelleProduit" :obj="produit" field="mutuelle_id"/>
            <b-col md="6">
              <b-form-group label="Logo:">
                <div class="d-block text-center">
                  <b-button-group>
                    <b-button v-if="produit.logo === null" variant="outline-primary" class="btn-icon" @click="openMFile('logo')"><i class="fa fa-plus"></i></b-button>
                    <b-button v-if="produit.logo != null" variant="light-primary" class="btn-icon" :href="$api.link(produit.logo)" target="_blank"><i class="fa fa-eye text-white"></i></b-button>
                    <b-button v-if="produit.logo != null" variant="light-danger" class="btn-icon" @click="produit.logo = null"><i class="fa fa-trash"></i></b-button>
                  </b-button-group>
                </div>
              </b-form-group>
            </b-col>
            <FieldForm model="MutuelleProduit" :obj="produit" field="produit"/>
            <FieldForm model="MutuelleProduit" :obj="produit" field="formule"/>            
            <FieldForm model="MutuelleProduit" :obj="produit" field="join_key" v-if="$api.user.role_id === 1"/>            
            <FieldForm model="MutuelleProduit" :obj="produit" field="hospi"/>            
            <FieldForm model="MutuelleProduit" :obj="produit" field="responsable"/>            
          </b-row>
        </b-card>
        <b-card class="card-search">              
          <b-row>
            <b-col md="6" v-for="(d,i) in produit.docs" :key="i">
              <b-form-group :label="d.key.indexOf('IPID') > -1 ? 'IPID' : d.key">
                <div class="d-block text-center">
                  <b-button-group v-if="i === 0">                    
                    <b-button v-if="d.value === null" variant="outline-primary" class="btn-icon" @click="openMFile(d.key, i)"><i class="fa fa-plus"></i></b-button>
                    <b-button v-if="d.value != null" variant="light-primary" class="btn-icon" :href="$api.link(d.value)" target="_blank"><i class="fa fa-eye text-white"></i></b-button>
                    <b-button v-if="d.value != null && $api.user.role_id === 1" variant="light-danger" class="btn-icon" @click="d.value = null"><i class="fa fa-trash"></i></b-button>
                  </b-button-group>
                  <b-button-group v-else>                    
                    <b-button v-if="d.value === null" variant="outline-primary" class="btn-icon" @click="openMFile(d.key, i)"><i class="fa fa-plus"></i></b-button>
                    <b-button v-if="d.value != null" variant="light-primary" class="btn-icon" :href="$api.link(d.value)" target="_blank"><i class="fa fa-eye text-white"></i></b-button>
                    <b-button v-if="d.value != null" variant="light-danger" class="btn-icon" @click="d.value = null"><i class="fa fa-trash"></i></b-button>
                  </b-button-group>
                </div>                
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-card-group>  
      <b-card class="card-search mt-3">
        <b-card-title>Site</b-card-title>
        <b-row>
          <b-col md="3" v-for="(k, i) in Object.keys(produit.site)" :key="i">
            <b-form-group :label="k">
              <b-form-rating v-model="produit.site[k]" variant="warning" stars="4"></b-form-rating>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>        
      <b-row>
        <b-col md="12" v-for="(g, gi) in produit.garantie" :key="gi">
          <b-card class="mt-3 card-search">
            <div class="d-block text-center bg-light-info p-3 h5 rounded" v-b-toggle="'collapse-'+$api.format(gi)">
              {{ gi }}
            </div>
            <b-collapse :id="'collapse-'+$api.format(gi)" visible>
              <b-table-simple hover striped>                            
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>Titre</b-th>
                    <b-th>Valeur</b-th>              
                    <b-th>Etoiles</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>                  
                  <b-tr v-for="(data, di) in g" :key="di">        
                    <b-td><Input v-model="data.key" type="text" :edit="true"/></b-td>
                    <b-td><Input v-model="data.value" type="text" /></b-td>
                    <b-td><b-form-rating v-model="data.starts" variant="warning"></b-form-rating></b-td>
                  </b-tr>            
                </b-tbody>                  
              </b-table-simple>
            </b-collapse>            
          </b-card>
        </b-col>
      </b-row>
    </form>
    <b-modal id="modelFile" ref="modelFile" hide-footer title="Ajouter votre document" size="lg">
      <form ref="form_event" id="form_event">
        <b-row>
          <b-col md="6">
            <b-form-group label="Votre document">              
              <b-form-file @change="handleFile" placeholder="Choisissez votre document" drop-placeholder="Drop file here..."></b-form-file>
            </b-form-group>
          </b-col>                            
        </b-row>
        <div class="b-block text-center">
          <b-button variant="outline-primary" @click="$refs.eventAgenda.hide()">Fermer</b-button>
          <b-button variant="primary" class="ml-2" @click="uploadFile">Sauvegarder</b-button>
        </div>        
      </form>
    </b-modal>
  </div>
</template>
<script>
import FieldForm from '@/components/FieldForm';
import Input from '@/components/InputForm';
export default {
  name: "ProduitNouveau",
  components: {
    FieldForm,    
    Input,    
  },
  data(){
    return{
      tabIndex:0,
      save_loader:false,
      produit: false,
      tmpFile:{
        type:null,
        index:null,
        name:null,
        base64: null
      }   
    }
  },
  watch:{  
    'produit.mutuelle_id'(v){
      if(v != null){
        var m = this.$api.p('Mutuelle', v);        
        this.produit.mutuelle = m.titre;
      }
    }
  },
  computed:{
  },
  beforeMount() { 
  },
  mounted() {
    var query = '';
    var clone = this.$route.query.clone;
    var data = this.$route.query.data;
    if(clone != undefined){
      query = `?clone=${clone}`;
    }else if(data != undefined){      
      query = `?data=${data}`;
    }    
    if(query != ''){      
      this.$api.ajax('/produit/new'+query, null, res => {
        if(res.status){
          this.produit = res.data;
        }
      })
    }else{
      this.$api.ajax('/produit/'+this.$route.params.id, null, res => {
        if(res.status){
          this.produit = res.data;          
        }
      });
    }
  },
  methods: {
    checkForm(){
      var res = true;
      if(this.$refs.form.reportValidity() === false){
        res = false;
      }
      return res;
    },    
    save(after = null){
      if(this.save_loader === true){
        return false;
      }
      if(this.checkForm() === false){
        return false;
      };
      this.save_loader = true;
      this.$api.ajax('/produit/create', this.produit, res => {
        if(res.status === true){
          this.produit = res.data;
          if(this.$route.path.indexOf("nouveau") > -1){
            this.$router.push({path:"/produit/detail/"+this.produit.id});
          }
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.save_loader = false;
        if(after === 'clone'){
          this.$router.push({path:`/produit/nouveau?clone=${this.produit.id}`});
          setTimeout(() => {
            location.reload();
          }, 500);
        }else if(after === 'documents'){
          this.majDocuments();
        }
      })
    },
    openMFile(type, index = null){
      this.tmpFile.type = type;
      this.tmpFile.index = index;
      var check = null;
      switch (type) {
        case 'Plaquette':
          check = 'plaquette';
        break;
        case 'Conditions générales':
          check = 'cg';
        break;
        case "Bulletin d'adhésion":
          check = 'ba';
        break;
        case "Document d’information sur le produit d’assurance (IPID)":
          check = 'ipid';
        break;
        case "Notice produit":
          check = 'notice';
        break;
        case "Assistance":
          check = 'assistance';
        break;
        default:
        check = type;
        break;
      }
      var name = `${check}_${this.produit.mutuelle}_${this.produit.formule}`;
      this.tmpFile.name = this.$api.format(name)
      this.$refs.modelFile.show();
    },
    handleFile(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      this.load_file = true;
      reader.onload = (e) => {        
        this.tmpFile.base64 = e.target.result;
        this.file_name = fileUploaded.name;
        var reg = /(?:\.([^.]+))?$/;        
        var ext = reg.exec(fileUploaded.name)[1]; 
        ext = ext ?? 'pdf';        
        this.tmpFile.name += '.'+ext
      }
      reader.readAsDataURL(fileUploaded);
    },
    uploadFile(){      
      this.$refs.modelFile.hide();
      this.$api.ajax('/produit/document', this.tmpFile, res => {
        if(res.status === true){
          if(this.tmpFile.type === 'logo'){
            this.produit.logo = res.data;
          }else{
            this.produit.docs[this.tmpFile.index].value = res.data;
          }
          this.tmpFile = {
            type:null,
            index:null,
            name:null,
            base64: null
          }
        }
      });
    },
    majDocuments(){
      if(this.save_loader){
        return false;
      }
      this.save_loader = true;
      this.$api.ajax('/produit/documents/'+this.produit.id, null, res => {
        if(res.status){
          this.$bvToast.toast(res.data, {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.save_loader = false;
      })
    }
  }
}
</script>
<style>

#main-tabs__BV_tab_controls_ > .nav-item .nav-link.active{
  color: white !important;
}
#main-tabs__BV_tab_container_ > .tab-pane.card-body{
  padding: 0px;
  margin-top: 10px;
}
#main-tabs > .card-header:first-of-type {
  padding: 1rem 1.25rem !important;
}
.liste-custom {
  position: absolute;
  z-index: 9;
  width: 92%;
}
.select-pays select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
